import React from 'react'
import './services.css'

export default function Services() {
  return (
    <div className='services'>
      <div className='services__msg'>
        We understand the complexities of life
      </div>
      <div className='services__container'>        
        <div className='services__container__box'>
          <h2>Binging</h2>
          <p>Overdoing something for a short time</p>
        </div>

        <div className='services__container__box'>
          <h2>Negligence</h2>
          <p>Failure to meet a standard of behaviour</p>
        </div>

        <div className='services__container__box'>
          <h2>Demotivation</h2>
          <p>Lack of interest in and enthusiasm</p>
        </div>

        <div className='services__container__box'>
          <h2>Disabilities</h2>
          <p>Any conditions that increases the difficulties</p>
        </div>
      </div>

      <div className='services__life'>
        <h2>"Living it or leaving it"</h2>
        <p> What are you doing with your life? Your life is a simple process that can grow complex over time.
          Or maybe complicated aspects of your life are getting the attention and overlooking the simple parts.
          Existence, finding the purpose, following the path to your destination is what life may mean. </p>
      </div>

      <div className='services__work'>
        <h2>How do we work?</h2>
        <h3>Understand</h3>
        <p>As healthcare service providers, our primary goal is to understand you, your life, and your lifestyle.
          We try to establish a psychological connection with you and move forward to one of the best gifts of life, friendship.
          Once we have understood each other, we start discussing your problems.</p>

        <h3>Refer</h3>
        <p>We consider your safety as our subsequent requirement.
          So if any essential services are out of scope or clearance is required, we make a wise decision to refer you to
          the appropriate healthcare professional. Once this process is complete, we exchange our ideas and agree on
          similar philosophies.</p>

        <h3>Train</h3>
        <p>Once you've chosen to train with us, we start with setting a SMART goal.
          We make a training program and discuss it with you for any following changes.
          We work together to keep you motivated and bring various possibilities and positivities into your life.</p>

      </div>
    </div>
  )
}
