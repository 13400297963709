import React from 'react'
import Greeting from './Greeting/Greeting'
import Services from './Services/Services'
import './home.css'
function Home({openFn, greet}) {
  return (
    <div className='home'>
      <Greeting popup={openFn} greet={greet}/>
      <Services/>
    </div>
  )
}

export default Home