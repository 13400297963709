import React from 'react'
import './footer.css'
import whatsappQr from './qr-code.png'

export default function Footer() {
  const year = new Date().getFullYear();
  
  return (
    <div className='footer'>
      <h1>"Explore a mile in your style"</h1>
      <div className='footer__whatsApp'>
        <p>We'd love to hear from you. <br /><b>WhatsApp Us</b></p>
        <img src={whatsappQr} alt='' />
      </div>
      <div className='footer__address'>
        <div className='footer__address__details'>
          <div className='footer__address__details__box'>
            <h2>Our Address</h2>
            <p>Indiranagar, Bangalore - 560038</p>
          </div>
          <div className='footer__address__details__box'>
            <h2>Phone Number</h2>
            <p>+91 - 9742957160</p>
          </div>
          <div className='footer__address__details__box'>
            <h2>Email Address</h2>
            <p>help@thelazytown.com</p>
          </div>
        </div>
        <h3>© {year} The Lazy Town. All rights reserved. </h3>
      </div>
    </div>
  )
}