import './App.css'
import Navbar from "./Component/Navbar/Navbar"
import About from './Component/About/About'
import Home from './Component/Home/Home'
/* import HealthMafia from './Component/Health Mafia/HealthMafia' */
import Footer from './Component/Footer/Footer'
import Popup from './Component/popup/Popup'
import React, {useState,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
function App() {
  const [showText, setShowText] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const openPopup = () => {
    setShowPopup(prev => !prev);
};
setTimeout(() => {
  setShowText(false);
}, 5000);

  return (

    <Router>
      <Popup showpopup={showPopup} setshowpopup={setShowPopup} />
      <div className="App">
        <Navbar />
        
        <Routes>
          <Route path="/" exact element={<Home openFn={openPopup} greet = {showText}/>}></Route>
        </Routes>

        {/* <Routes>
          <Route path="/health-mafia" exact element={<HealthMafia />}></Route>
        </Routes> */}

        <Routes>
          <Route path="/about" exact element={<About />}></Route>
        </Routes>
 
       

        <Footer/>

      </div>

    </Router>
  );
}

export default App;