import React from 'react'
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './about.css'
import kundan from './insley.gif';
import monika from './monika.png';
import shantanu from './shantanu.png';
import pic3 from './training.webp';
import pic2 from './yoga.webp';
import pic1 from './children.webp';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
function About() {
  return (
    <div className="about">
      <div className='about__slider'>
        <Carousel showStatus={false} showThumbs={false} autoplay={true} infiniteLoop={true}>
          <div >
            <img alt='pic1' src={pic1} />
            
          </div>
          <div >
            <img alt='pic2' src={pic2} />
          </div>
          <div >
            <img alt='pic3' src={pic3}/>
          </div>
        </Carousel>
      </div>
      <div className='about__us'>
        <div className='about__about'>
          <h4 className='h4'> About Us </h4>
          <p className='p'>The Lazy Town believes everyone has the right to live a quality life.
            We provide online and offline services for personalized exercise and diet prescriptions.
            We also work on multiple aspects of health and help you overcome your barriers to life.
            We are excited to help you with your health and fitness journey. Join us, and let's explore a mile in
            your style.</p>
        </div>
        <div className='about__vision'>
          <h4 className='h4'>Vision &amp; Mission</h4>
          <p className='p vm' >To create awareness for a healthy lifestyle and a healthy world.<br className='br' />To encourage physical activity in daily routine.</p>
        </div>
        <div className='about__values'>
          <h4 className='h4'>Values</h4>
          <p className='p'>We are committed to providing peer-reviewed training, adapting to the client's needs,
            and assisting them to convert their goals into results.</p>

        </div>
      </div>
      <h4 className='custom' > Our Team</h4>
      <div className='about__team'>
        
        <div className="container">
          <div className="card">
            <div className="imgBx">
              <img src={kundan} alt='kundan kumar' />
            </div>
            <div className="contentBx">
              <h2>Kundan Kumar</h2>
              <h3 className='designation'>- CEO &amp; Founder</h3>
              <div className="social__icons">
                <a href='https://www.youtube.com/channel/UCL33zaWMN-2Y2gI1mRszN_A' target='_blank' rel="noreferrer noopener"><YouTubeIcon className='social__icon' /></a>
                <a href='https://www.instagram.com/thelazytownfitness/' target='_blank' rel="noreferrer noopener"><InstagramIcon className='social__icon' /></a>
              </div>
              <p> Hi! I'm Kundan Kumar; I love mastering my skills.
              </p>

            </div>
          </div>
        </div>

        <div className="container">
          <div className="card">
            <div className="imgBx">
              <img src={monika} alt='Monika Gupta' />
            </div>
            <div className="contentBx">
              <h2>Monika Gupta</h2>
              <h3 className='designation'> - Quins Manager</h3>
              <div className="social__icons">
                <a href='https://www.linkedin.com/in/monika-gupta-0a8419108' target='_blank' rel="noreferrer noopener"><LinkedInIcon className='social__icon' /></a>
                <a href='https://www.instagram.com/iammonika/' target='_blank' rel="noreferrer noopener"><InstagramIcon className='social__icon' /></a>
              </div>
              <p> Hi! I'm Monika Gupta; I enjoy exploring the world.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="card">
            <div className="imgBx">
              <img src={shantanu} alt='Shantanu Mishra' />
            </div>
            <div className="contentBx">
              <h2>Shantanu Mishra</h2>
              <h3 className='designation'> - Software Engineer</h3>
              <div className="social__icons">
                <a href='https://www.linkedin.com/in/me-shantanu/' target='_blank' rel="noreferrer noopener"><LinkedInIcon className='social__icon' /></a>
                <a href='https://www.instagram.com/i.am.shantanu.mishra/' target='_blank' rel="noreferrer noopener"><InstagramIcon className='social__icon' /></a>
              </div>
              <p> Hi! I'm Shantanu Mishra; I love Biriyanis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About