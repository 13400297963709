import React from 'react'
import SingleNav from './SingleNav'
import { Link } from "react-router-dom"
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useState , useEffect } from 'react'
import logo from './logo.png';
import './nav.css'
function Navbar() {
  const [navbar, setNavbar] = useState(false)
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
  })
  return (
    <div className={navbar ? "navbar active" : "navbar"}>
        <div className='brand'>
            <Link to="/" className='brand__link'>
              <img src={logo} className={navbar ? "brand__logo active__brand__logo" : "brand__logo"} alt="logo" />
            </Link>
        </div>
        <div className='navbar__links'>
            <SingleNav nav={navbar} path="/" Icon={HomeIcon} text="HOME" />
            <SingleNav nav={navbar} path="/about" Icon={InfoIcon} text="ABOUT" />
            <a href="https://www.instagram.com/thelazytownfitness/" rel="noreferrer noopener" target="_blank"  > 
            <InstagramIcon className={navbar ? "insta__icon active__insta" : "insta__icon"}/>
            </a>
        </div>
    </div>
  )
}

export default Navbar