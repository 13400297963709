import React, {useRef, useState} from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@mui/material';
import kundan from './insleyJourney.webp';
import emailjs from 'emailjs-com';
import './popup.css'
function Popup({ showpopup, setshowpopup }) {
  const [title, setTitle] = useState('')
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_io4bx2w', 'template_uxgl19y', form.current, 'zPbwrm5FJxWXkFQFu')
      .then((result) => {
          console.log(result.text);
          alert(`We have received ${title} successfully.`);
          setshowpopup(false);
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };


  return (
    <>
      {showpopup ? <div className='popup'>
        <div className="popup__container">
          <div className='popup__img'>
            <img src={kundan} alt="lazyTown" />
          </div>
          <div className='popup__cross__btn'>
            <Button className='cross__btn' onClick={() => setshowpopup(false)}>
              <CloseRoundedIcon className='cross__icon' />
            </Button>
          </div>
          <div className='popup__data'>
            <h2>Get in touch</h2>
            <h4>Health Mafia's Updates &amp; Surprises</h4>
            <form className="contact" ref={form} onSubmit={sendEmail}> 
            <input className="contact__input" type="text" maxlength="10" name="user_number" pattern="[7-9]{1}[0-9]{9}" placeholder='type your number'
                  title="Phone number with 7-9 and remaing 9 digit with 0-9" onChange={event => setTitle(event.target.value)}></input>
              <button className='contact__btn' type="submit" value="Send" >SUBMIT</button> </form>
          </div>
        </div>
      </div>
        : null
      }
    </>
  )
}
export default Popup