import React, { useState } from 'react'
import './greeting.css'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import IH from './insley.jpg'
import pt from './pt.jpg';
import lc from './lc.jpg';
import ep from './ep.jpg';
import dp from './dp.jpg';
import bc from './bc.jpg';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const items = [



  <div className='greet__container__box item' data-value='1'>
    <div className='greet__container__img'>
      <img src={lc} alt="Lifestyle Counseling" /></div>
    <p>LIFESTYLE COUNSELING</p>
  </div>,

  <div className='greet__container__box item' data-value='2'>
    <div className='greet__container__img'>
      <img src={ep} alt="Exercise Prescription" /></div>
    <p>EXERCISE PRESCRIPTION</p>
  </div>,

  <div className='greet__container__box item' data-value='3'>
    <div className='greet__container__img'>
      <img src={dp} alt="Dietary Prescription" /></div>
    <p>DIETARY PRESCRIPTION</p>
  </div>,

  <div className='greet__container__box item' data-value='4'>
    <div className='greet__container__img'>
      <img src={pt} alt="Personal Training" /></div>
    <p>PERSONAL TRAINING</p>
  </div>,

  <div className='greet__container__box item' data-value='5'>
    <div className='greet__container__img'>
      <img src={bc} alt="Boot Camps" /></div>
    <p>BOOT CAMPS</p>
  </div>,
];
export default function Greeting({ popup, greet }) {


  var greeting_message = ''
  var myDate = new Date()
  var hrs = myDate.getHours()
  const style = {
    gm: {
      color: "#1DB954"
    },
    gf: {
      color: "#FFC0CB"
    },
    ge: {
      color: "#003b59"
    }

  }
  let col
  if (hrs < 12) {
    greeting_message = 'Good Morning';
    col = style.gm;
  } else if (hrs >= 12 && hrs <= 17) {
    greeting_message = 'Good Afternoon';
    col = style.gf;
  }
  else if (hrs >= 17 && hrs <= 24) {
    greeting_message = 'Good Evening';
    col = style.ge;
  }
  let start__mess
  if (greet) {
    start__mess = <h1 className="greeting__msg"><span style={col}>{greeting_message}</span> Quins!</h1>;
  } else {
    start__mess = <h1 className='home__quote'> ❝ Sometimes, reaching out and taking
      someone's hand is the beginning of a journey. ❞ <br /> <span> - Vera Nazarian </span> </h1>;
  }


  return (
    <div className="greeting">
      <div className='hom'>
        <div className='home__left'>
          <img src={IH} alt='' />
        </div>
        <div className='home__right'>
          {start__mess}
          <button onClick={popup} className='greeting__btn' >START YOUR JOURNEY</button>

        </div></div>
      <AliceCarousel
        autoPlay
        paddingLeft={180}
        autoPlayStrategy="none"
        autoPlayInterval={1000}
        animationDuration={1000}
        animationType="fadeout"
        disableButtonsControls={true}
        infinite
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
      />

    </div>
  )
}