import React from 'react'
import { Link } from 'react-router-dom'
import './nav.css';
function SingleNav({path,Icon,text,nav}) {
  return (
    <Link className='singlenav__link' to={path}>
        <div className={nav ? "singlenav active__nav__icon" : "singlenav"}>
            <Icon className={nav ? "singlenav__icon active__nav" : "singlenav__icon"} />
            <h3 className={nav ? "singlenav__text active__nav" : "singlenav__text"}>{text}</h3>
        </div>
    </Link>
  )
}

export default SingleNav